<template>
  <div>
    <validation-observer ref="simpleRules">
      <b-row>
        <b-col>
          <div id="component-breadcrumbs">
            <b-breadcrumb
              class="breadcrumb-slash"
              :items="breadcrumbItems"
            />
          </div>
        </b-col>
        <b-col v-if="readOnly && UserType === 'User'">
          <div class="float-right mr-1">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mr-1"
              @click.prevent="editScreen()"
            >
              Edit
            </b-button>
          </div>
        </b-col>
      </b-row>
      <hr>
      <div class="d-flex justify-content-between">
        <h1>Cases for Subject</h1>
        <div class="col-3">
          <!--Spacing-->
        </div>
      </div>
      <!-- TABLE -->
      <vue-good-table
        :columns="columns"
        :rows="rows"
        :search-options="{
          enabled: false,
        }"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >
          <!-- Column: Name -->
          <span
            v-if="props.column.field === 'InternalNumber'"
            class="text-nowrap"
          >
            <router-link :to="'/cases/' + props.row.CaseID + '/detail'">{{ props.row.InternalNumber }}</router-link>
          </span>

          <!-- Column: Common -->
          <span v-else-if="props.column.field === 'Created'">
            {{ props.formattedRow[props.column.field] | dateFormat }}
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
      </vue-good-table>

      <!-- SUBJECT DETAILS -->
      <div class="custom-p-2">
        <div class="accordion">
          <div
            class="accordion-title"
            @click="toggleAccordion('SUBJECT_DETAILS')"
          >
            <div
              class="collapse-arrow"
              :class="{ open: tab.SUBJECT_DETAILS }"
            />
            <h3>Subject Details</h3>
          </div>
          <b-row
            v-if="tab.SUBJECT_DETAILS"
            class="p-1"
          >
            <template>
              <b-col md="9">
                <b-row>
                  <b-col md="3">
                    <validation-provider
                      #default="{ errors }"
                      name="First Name"
                      rules="required"
                    >
                      <b-form-group
                        label="First Name"
                        label-for="firstName"
                        class="required-data"
                        :state="errors.length > 0 ? false : null"
                      >
                        <b-form-input
                          id="firstName"
                          v-model="Subject.FirstName"
                          :readonly="readOnly"
                          placeholder=""
                          name="firstName"
                        />
                        <small
                          class="text-danger"
                          :state="errors.length > 0 ? false : null"
                        >
                          {{ errors[0] }}
                        </small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col md="3">
                    <b-form-group
                      label="Middle Name"
                      label-for="middleName"
                    >
                      <b-form-input
                        id="middleName"
                        v-model="Subject.MiddleName"
                        :readonly="readOnly"
                        placeholder=""
                        name="middleName"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="3">
                    <validation-provider
                      #default="{ errors }"
                      name="Last Name"
                      rules="required"
                    >
                      <b-form-group
                        class="required-data"
                        :state="errors.length > 0 ? false : null"
                        label="Last Name"
                        label-for="lastName"
                      >
                        <b-form-input
                          id="lastName"
                          v-model="Subject.LastName"
                          :readonly="readOnly"
                          placeholder=""
                          name="lastName"
                        />
                        <small
                          class="text-danger"
                          :state="errors.length > 0 ? false : null"
                        >
                          {{ errors[0] }}
                        </small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col md="3">
                    <b-form-group
                      label="Alias"
                      label-for="alias"
                    >
                      <b-form-input
                        id="alias"
                        v-model="Subject.Alias"
                        :readonly="readOnly"
                        placeholder=""
                        name="alias"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col md="3">
                    <b-form-group
                      label="SSN"
                      label-for="ssn"
                    >
                      <b-input-group>
                        <b-form-input
                          v-if="readOnly || getCurrentScopes.indexOf('st2.viewssnanddob') === -1"
                          id="ssnInput"
                          :value="fullSocialSecurityNoIce ? fullSocialSecurityNo : Subject.SocialSecurityNo"
                          name="ssnInput"
                          :readonly="true"
                        />
                        <b-form-input
                          v-else
                          id="ssn"
                          v-model="Subject.SocialSecurityNo"
                          :formatter="ssnOnChange"
                          v-mask="'###-##-####'"
                          type="text"
                          :readonly="readOnly"
                          placeholder=""
                          name="ssn"
                          @blur.native="handleSocialSecurityNo"
                          @keypress.enter="handleSocialSecurityNo"
                        />
                        <div class="ice-icon">
                          <feather-icon
                            v-if="readOnly && getCurrentScopes.indexOf('st2.viewssnanddob') !== -1"
                            class="cursor-pointer"
                            :icon="fullSocialSecurityNoIce? 'EyeOffIcon' : 'EyeIcon'"
                            @click="toggleVisibilityFields('fullSocialSecurityNoIce')"
                          />
                        </div>
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <b-col md="3">
                    <b-form-group
                      label="DOB"
                      label-for="dob"
                    >
                      <b-input-group>
                        <b-form-input
                          v-if="readOnly || getCurrentScopes.indexOf('st2.viewssnanddob') === -1 || (!fullDateOfBirth && Subject.DateOfBirth && readOnly)"
                          id="dobInput"
                          :value="fullDateOfBirthIce ? fullDateOfBirth : DOBForInput"
                          :readonly="true"
                          name="dobInput"
                        />
                        <b-form-input
                          v-else
                          id="dob"
                          v-model="Subject.DateOfBirth"
                          type="date"
                          placeholder=""
                          name="dob"
                          min="1900-01-01"
                          max="2099-12-31"
                          :readonly="readOnly"
                          @blur="isValidDateOfBirth()"
                        />

                        <div
                          class="ice-icon"
                        >
                          <feather-icon
                            v-if="readOnly && getCurrentScopes.indexOf('st2.viewssnanddob') !== -1"
                            class="cursor-pointer"
                            :icon="fullDateOfBirthIce? 'EyeOffIcon' : 'EyeIcon'"
                            @click="toggleVisibilityFields('fullDateOfBirthIce')"
                          />
                        </div>
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <b-col md="3">
                    <b-form-group
                      label="Driver’s License No."
                      label-for="driversLicenseNo"
                    >
                      <b-input-group>
                        <b-form-input
                          v-if="readOnly || getCurrentScopes.indexOf('st2.viewssnanddob') === -1"
                          id="dlnInput"
                          :value="fullDriversLicenseNoIce ? fullDriversLicenseNo : Subject.DriversLicenseNo"
                          :readonly="true"
                          name="dlnInput"
                        />
                        <b-form-input
                          v-else
                          id="driversLicenseNo"
                          v-model="Subject.DriversLicenseNo"
                          :readonly="readOnly"
                          placeholder=""
                          name="driversLicenseNo"
                        />
                        <div
                          class="ice-icon"
                        >
                          <feather-icon
                            v-if="readOnly && getCurrentScopes.indexOf('st2.viewssnanddob') !== -1"
                            class="cursor-pointer"
                            :icon="fullDriversLicenseNoIce? 'EyeOffIcon' : 'EyeIcon'"
                            @click="toggleVisibilityFields('fullDriversLicenseNoIce')"
                          />
                        </div>
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <b-col md="3">
                    <b-form-group
                      label="State"
                      label-for="state"
                    >
                      <v-select
                        id="state"
                        v-model="Subject.DriversLicenseState"
                        :dir="
                          $store.state.appConfig.layout.isRTL
                            ? 'rtl'
                            : 'ltr'
                        "
                        label="title"
                        :options="StatesList"
                        placeholder=""
                        name="state"
                        :disabled="readOnly"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
              <b-col>
                <DropSubjectImage
                  :is-read-only="readOnly"
                  @uploadSubjectImage="uploadSubjectImage"
                />
                <div v-if="Subject.Image && Subject.Image.length">
                  <b-form-checkbox
                    id="contract"
                    v-model="Subject.IsPhotoVerified"
                    name="vendingService"
                    :disabled="readOnly"
                  >
                    Photo Verified
                  </b-form-checkbox>
                  <v-select
                    id="source"
                    v-model="Subject.PhotoSource"
                    label="title"
                    :options="sourceOption"
                    placeholder="Source"
                    name="source"
                    :disabled="readOnly"
                  />
                </div>
                <b-button
                  v-if="showViewAllButton()"
                  type="button"
                  variant="primary"
                  class="mb-1 mt-1"
                  @click="viewAllImages()"
                >
                  <span>View All Images</span>
                </b-button>
              </b-col>
            </template>
          </b-row>
          <b-row class="pl-1">
            <b-col md="3">
              <b-form-group
                label="Phone 1"
                label-for="phone1"
              >
                <b-form-input
                  id="phone1"
                  v-model="Subject.Phone1"
                  v-mask="'(###) ###-####'"
                  placeholder=""
                  name="phone1"
                  :readonly="readOnly"
                />
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group
                label="Type"
                label-for="phoneType1"
              >
                <v-select
                  id="phoneType1"
                  v-model="Subject.Phone1Type"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :options="phoneType"
                  placeholder=""
                  name="phoneType1"
                  :disabled="readOnly"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="pl-1">
            <b-col md="3">
              <b-form-group
                label="Phone 2"
                label-for="phone2"
              >
                <b-form-input
                  id="phone2"
                  v-model="Subject.Phone2"
                  v-mask="'(###) ###-####'"
                  placeholder=""
                  name="phone2"
                  :readonly="readOnly"
                />
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group
                label="Type"
                label-for="phoneType2"
              >
                <v-select
                  id="phoneType2"
                  v-model="Subject.Phone2Type"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :options="phoneType"
                  placeholder=""
                  name="phoneType2"
                  :disabled="readOnly"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <!-- SUBJECT LOCATIOn -->
          <div class="accordion">
            <div
              class="accordion-title"
              @click="toggleAccordion('SUBJECT_LOCATION')"
            >
              <div
                class="collapse-arrow"
                :class="{ open: tab.SUBJECT_LOCATION }"
              />
              <h3>Subject Location</h3>
            </div>
            <b-row v-if="tab.SUBJECT_LOCATION">
              <b-col>
                <b-row>
                  <AddressForm
                    v-for="(address, index) of Subject.SubjectAddress"
                    :key="index"
                    :address="address"
                    :index="index"
                    :is-read-only="readOnly"
                    @updateAddress="updateAddress"
                    @deleteAddress="deleteAddress"
                  />
                </b-row>
                <b-button
                  type="button"
                  variant="primary"
                  class="mb-1"
                  style="margin-top: 1rem"
                  :disabled="readOnly"
                  @click="addAddress()"
                >
                  <span>+ Add Address</span>
                </b-button>
              </b-col>
            </b-row>
          </div>
          <!-- SUBJECT DESCRIPTION -->
          <div class="accordion">
            <div
              class="accordion-title"
              @click="toggleAccordion('SUBJECT_DESCRIPTION')"
            >
              <div
                class="collapse-arrow"
                :class="{ open: tab.SUBJECT_DESCRIPTION }"
              />
              <h3>Subject Description</h3>
            </div>
            <b-row
              v-if="tab.SUBJECT_DESCRIPTION"
              class="pt-1 pb-1"
            >
              <b-col>
                <b-row>
                  <b-col>
                    <b-row>
                      <b-col md="2">
                        <b-form-group
                          label="Sex"
                          label-for="sex"
                        >
                          <v-select
                            id="sex"
                            v-model="Subject.Gender"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            label="title"
                            :options="sexOptions"
                            placeholder=""
                            name="sex"
                            :disabled="readOnly"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col>
                        <b-form-group
                          label="Height"
                          label-for="height"
                        >
                          <div class="d-flex">
                            <span class="d-flex align-items-baseline height-input">
                              <b-form-input
                                id="height"
                                v-model="HeightFt"
                                placeholder=""
                                name="heightFt"
                                class="mr-1"
                                :readonly="readOnly"
                              />
                              <p>ft</p>
                            </span>
                            <span class="d-flex align-items-baseline height-input">
                              <b-form-input
                                v-model="HeightIn"
                                placeholder=""
                                name="heightIn"
                                class="mr-1 ml-1"
                                :readonly="readOnly"
                              />
                              <p>in</p>
                            </span>
                          </div>
                        </b-form-group>
                      </b-col>
                      <b-col>
                        <b-form-group
                          label="Weight"
                          label-for="weight"
                        >
                          <b-form-input
                            id="weight"
                            v-model="Subject.Weight"
                            placeholder=""
                            name="weight"
                            :readonly="readOnly"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col>
                        <b-form-group
                          label="Age (calculated from DOB)"
                          label-for="age"
                        >
                          <b-form-input
                            id="age"
                            v-model="Age"
                            placeholder=""
                            name="age"
                            :readonly="true"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col>
                        <b-form-group
                          label="Married"
                          label-for="married"
                        >
                          <b-form-input
                            id="married"
                            v-model="Subject.Spouse"
                            placeholder=""
                            name="married"
                            :readonly="readOnly"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col>
                        <b-form-group
                          label="Children"
                          label-for="children"
                        >
                          <b-form-input
                            id="children"
                            v-model="Subject.Children"
                            placeholder=""
                            name="children"
                            :readonly="readOnly"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row class="pb-1">
                      <b-col>
                        <b-form-group
                          label="Race"
                          label-for="race"
                        >
                          <b-form-input
                            id="race"
                            v-model="Subject.Race"
                            placeholder=""
                            name="race"
                            :readonly="readOnly"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col>
                        <b-form-group
                          label="Hair Color"
                          label-for="hairColor"
                          class="text-nowrap"
                        >
                          <b-form-input
                            id="hairColor"
                            v-model="Subject.Hair"
                            placeholder=""
                            name="hairColor"
                            :readonly="readOnly"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col>
                        <b-form-group
                          label="Hair Style"
                          label-for="hairStyle"
                          class="text-nowrap"
                        >
                          <b-form-input
                            id="hairStyle"
                            v-model="Subject.HairStyle"
                            placeholder=""
                            name="hairStyle"
                            :readonly="readOnly"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col>
                        <b-form-group
                          label="Eye Color"
                          label-for="eyeColor"
                          class="text-nowrap"
                        >
                          <b-form-input
                            id="eyeColor"
                            v-model="Subject.Eyes"
                            placeholder=""
                            name="eyeColor"
                            :readonly="readOnly"
                          />
                        </b-form-group></b-col>
                      <b-col>
                        <b-form-group
                          label="Glasses"
                          label-for="glasses"
                        >
                          <b-form-input
                            id="glasses"
                            v-model="Subject.Glasses"
                            placeholder=""
                            name="glasses"
                            :readonly="readOnly"
                          />
                        </b-form-group></b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="3">
                    <p>Other Characteristics (if different than above)</p>
                  </b-col>
                  <b-col>
                    <div
                      class="collapse-textarea"
                      :class="{expanded: !isExpandedOtherCharacteristics}"
                    >
                      <textarea
                        v-model="Subject.OtherCharacteristics"
                        class="expandable-textarea"
                        :class="[isExpandedOtherCharacteristics ? 'not-expanded' : 'expanded']"
                        contentEditable
                        :readonly="readOnly"
                      />
                      <div @click="isExpandedOtherCharacteristics = !isExpandedOtherCharacteristics">
                        <span
                          class="expand-arrow mt-n1"
                          :class="[isExpandedOtherCharacteristics ? 'not-expanded' : 'expanded']"
                        >&#187;</span>
                      </div>
                    </div>
                  </b-col>
                </b-row>
                <b-row class="pt-1 pb-1">
                  <b-col md="3">
                    <p>Known Vehicles</p>
                  </b-col>
                  <b-col>
                    <div
                      class="collapse-textarea"
                      :class="{expanded: !isExpandedKnownVehicles}"
                    >
                      <textarea
                        v-model="Subject.VehicleInformation"
                        class="expandable-textarea"
                        :class="[isExpandedKnownVehicles ? 'not-expanded' : 'expanded']"
                        contentEditable
                        :readonly="readOnly"
                      />
                      <div @click="isExpandedKnownVehicles = !isExpandedKnownVehicles">
                        <span
                          class="expand-arrow mt-n1"
                          :class="[isExpandedKnownVehicles ? 'not-expanded' : 'expanded']"
                        >&#187;</span>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </div>

        </div>
      </div>
      <b-row
        v-if="!readOnly"
        class="pb-2 pt-2"
      >
        <b-col>
          <div class="d-flex justify-content-between">
            <div>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="outline-secondary"
                @click="resetForm()"
              >
                Cancel
              </b-button>
            </div>
            <div>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1"
                @click.prevent="validationForm()"
              >
                Save
              </b-button>
            </div>
          </div>
        </b-col>
      </b-row>
    </validation-observer>
    <b-modal
      v-model="modalShow"
      hide-backdrop
      size="md"
      centered
    >
      <template #modal-header="">
        <div class="d-flex mt-1 mb-n3 text-center flex-column justify-content-center">
          <h2 class="text-center">
            <strong>There is a match for this SSN under claim number<br>[<span v-text="Subject.SocialSecurityNo" />]</strong>
          </h2>
          <h5 class="text-center">
            Do you wish to review this case?
          </h5>
        </div>
      </template>

      <template #modal-footer="">
        <div class="d-flex mb-1 justify-content-center flex-grow-1">
          <div class="w-50 d-flex justify-content-center">
            <b-button
              type="reset"
              variant="outline-secondary"
              size="md"
              @click="chancelCase()"
            >
              Ignore
            </b-button>
          </div>
          <div class="w-50 d-flex justify-content-center">
            <b-button
              type="submit"
              variant="primary"
              class="mr-1"
              size="md"
              @click="reviewCase()"
            >
              View Case
            </b-button>
          </div>
        </div>
      </template>
    </b-modal>
    <b-modal
      id="modal-all-images"
      ok-variant="primary"
      ok-title="Set as current image"
      :ok-disabled="selectedImage === null"
      modal-class="modal-primary"
      centered
      hide-header-close
      @ok="changeImage()"
      @cancel="selectedImage = null"
      @hide="selectedImage = null"
    >
      <div class="images-wrapper">
        <div
          v-for="(item, index) in RelatedImages"
          :key="index"
        >
          <div
            class="image-wrapper"
            :class="selectedImage === index ? 'selected-image' : ''"
            @click="chooseImage(index)"
          >
            <img class="img-block" :src="item.Image">
            <b-form-checkbox
              v-if="item.IsPhotoVerified"
              class="verified"
              v-model="item.IsPhotoVerified"
              name="verified"
              :disabled="true"
            >
              <div class="tooltip-source">{{ item.Source }}</div>

            </b-form-checkbox>
            <div class="date-field">{{ item.CaseCreatedDate | dateFormat }}</div>
            <div class="link-title">
              <router-link
                v-if="UserType === 'User'"
                class="link-text"
                :to="'/subject/' + item.SubjectId"
                target="_blank"
              >{{ item.InternalNumber }}</router-link>
              <div v-else class="link-text">{{ item.InternalNumber }}</div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BBreadcrumb,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BFormCheckbox,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "vue-good-table/dist/vue-good-table.css";
import { required } from "@validations";
import store from "@/store";
import { mapGetters, mapMutations } from "vuex";
import APIService from "@core/utils/APIService";
import { VueGoodTable } from "vue-good-table";
import DropSubjectImage from "@/views/cases/components/DropSubjectImage";
import { ValidationProvider, ValidationObserver } from "vee-validate";

import AddressForm from "@/views/cases/components/AddressForm";
import vSelect from "vue-select";
import { tryGetDate } from '@core/utils/filter'
import axios from "axios";

const axiosIns = axios.create({
  responseType: 'blob',
  headers: {
    'Content-Type': 'text/html',
  },
})

const apiService = new APIService();

export default {
  components: {
    BInputGroup,
    BRow,
    BCol,
    BButton,
    BBreadcrumb,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    VueGoodTable,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    DropSubjectImage,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    AddressForm,
  },
  directives: {
    Ripple,
  },
  filters: {
    dateFormat(val) {
      if (val) return val.substr(0, 10);
      return val
    },
  },
  data() {
    return {
      SubjectID: "",
      required,
      UserType: "",
      DOBForInput: "",
      tryGetDate,

      liteDateOfBirth: "",
      liteDriversLicenseNo: "",
      liteSocialSecurityNo: "",

      fullDateOfBirth: "",
      fullDriversLicenseNo: "",
      fullSocialSecurityNo: "",

      fullDateOfBirthIce: false,
      fullDriversLicenseNoIce: false,
      fullSocialSecurityNoIce: false,

      columns: [
        {
          label: "Internal Number",
          field: "InternalNumber",
        },
        {
          label: "Status",
          field: "Status",
        },
        {
          label: "Subject",
          field: "Subject",
        },
        {
          label: "Location",
          field: "Location",
        },
        {
          label: "Created",
          field: "Created",
        },
      ],
      rows: [],
      direction() {
        if (store.state.appConfig.layout.isRTL) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.dir = true;
          return this.dir;
        }
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = false;
        return this.dir;
      },
      tab: {
        SUBJECT_DETAILS: true,
        SUBJECT_LOCATION: false,
        SUBJECT_DESCRIPTION: false,
      },
      readOnly: true,

      Subject: {},
      DateOfBirthComplete: true,

      Age: "",
      HeightFt: 0,
      HeightIn: 0,

      sourceOption: ["Client Provided", "Research Team", "Other"],
      sexOptions: ["Male", "Female"],
      phoneType: ["Work", "Cell", "Home"],
      StatesList: [
        'AK', 'AL', 'AR', 'AZ', 'CA', 'CO', 'CT', 'DC', 'DE', 'FL', 'GA',
        'HI', 'IA', 'ID', 'IL', 'IN', 'KS', 'KY', 'LA', 'MA', 'MD', 'ME',
        'MI', 'MN', 'MO', 'MS', 'MT', 'NC', 'ND', 'NE', 'NH', 'NJ', 'NM',
        'NV', 'NY', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX',
        'UT', 'VA', 'VT', 'WA', 'WI', 'WV', 'WY'
      ],

      matchCaseID: "",
      modalShow: false,

      isExpandedKnownVehicles: false,
      isExpandedOtherCharacteristics: false,

      searchFilterTerm: "",
      isExpanded: false,

      selectedImage: null,
      RelatedImages: [],
    };
  },
  computed: {
    ...mapGetters({
      getCurrentScopes: "scopes/getCurrentScopes",
      getCase: "cases/getCase",
    }),
    breadcrumbItems: function () {
      return [
        {
          text: "Home",
          href: "/home",
        },
        {
          text: this.subjectName
        },
      ];
    },
    subjectName: function() {
      if (this.Subject === undefined || this.Subject === null) {
        return "Loading...";
      }

      if (this.Subject.FirstName === undefined || this.Subject === null) {
        return "Unknown";
      }

      return this.Subject.FirstName + ' ' + this.Subject.LastName;
    },
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current: "light-primary",
        Professional: "light-success",
        Rejected: "light-danger",
        Resigned: "light-warning",
        Applied: "light-info",
        /* eslint-enable key-spacing */
      };

      return (status) => statusColor[status];
    },
    getSubjectData() {
      return this.getSubject;
    },
  },
  watch: {
    getSubjectData(val) {
      this.fillSubject(val);
    },
    HeightFt() {
      this.Subject.Height = this.HeightFt + " ft " + this.HeightIn;
    },
    HeightIn() {
      this.Subject.Height = this.HeightFt + " ft " + this.HeightIn;
    },
    readOnly(val) {
      if (!val) {
        this.liteDateOfBirth = this.Subject.DateOfBirth
        this.liteDriversLicenseNo = this.Subject.DriversLicenseNo
        this.liteSocialSecurityNo = this.Subject.SocialSecurityNo
        this.toggleVisibilityFields("", true);
      } else {
        this.Subject.DateOfBirth = this.liteDateOfBirth
        this.Subject.DriversLicenseNo = this.liteDriversLicenseNo
        this.Subject.SocialSecurityNo = this.liteSocialSecurityNo
      }
    },
  },
  async created() {
    try {
      this.UserType = localStorage.getItem("userType");
      this.loadSubjectPage();
    } catch (err) {
      this.error = err;
    }
  },
  methods: {
    ...mapMutations({
      setSubject: "cases/setSubject",
      setUploadUrl: "cases/setUploadUrl",
    }),
    showToast(variant, position, timeout, data) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notification",
            icon: "InfoIcon",
            text: data,
            variant,
          },
        },
        {
          position,
          timeout,
        }
      );
    },

    loadSubjectPage() {
      this.SubjectID = this.$route.params.subjectID;
      if (this.SubjectID) {
        apiService
            .get("subject/" + this.SubjectID)
            .then((response) => {
              let subject = response.data;
              this.setSubject(subject);
              this.fillSubject(subject);
              this.rows = response.data.Cases.Cases;
              this.selectedImage = null;
            });
      }
    },

    toggleAccordion(tab) {
      this.tab[tab] = !this.tab[tab];
    },

    fillSubject(val) {
      this.DOBForInput = val.DateOfBirth;
      if (val.DateOfBirth && this.readOnly) val = {...val, DateOfBirth: this.tryGetDate(val.DateOfBirth)}
      if (!this.readOnly) {
        val.DateOfBirth = this.fullDateOfBirth ? this.tryGetDate(this.fullDateOfBirth) : val.DateOfBirth
        val.DriversLicenseNo = this.fullDriversLicenseNo ? this.fullDriversLicenseNo : val.DriversLicenseNo
        val.SocialSecurityNo = this.fullSocialSecurityNo ? this.fullSocialSecurityNo : val.SocialSecurityNo
      } else {
        val.DateOfBirth = (this.fullDateOfBirthIce && this.fullDateOfBirth) ? this.fullDateOfBirth : val.DateOfBirth
        val.DriversLicenseNo = (this.fullDriversLicenseNoIce && this.fullDriversLicenseNo) ? this.fullDriversLicenseNo : val.DriversLicenseNo
        val.SocialSecurityNo = (this.fullSocialSecurityNoIce && this.fullSocialSecurityNo) ? this.fullSocialSecurityNo : val.SocialSecurityNo
      }

      this.Subject = val;
      this.RelatedImages = val.RelatedImages.filter(i => i.Image)
      this.Age = this.getCurrentScopes.indexOf('st2.viewssnanddob') !== -1 ? this.fillAge(this.tryGetDate(val.DateOfBirth)) : val.Age
      this.splitHeight(val.Height);
    },

    splitHeight(Height) {
      if (Height && Height.indexOf(" ft ") !== -1) {
        const heightAr = Height.split(" ft ");
        this.HeightFt = heightAr[0];
        this.HeightIn = heightAr[1];
      }
    },

    fillAge(DateOfBirth) {
      if (!DateOfBirth) return "";
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth();
      const currentDay = currentDate.getDate();
      const DOB = new Date(DateOfBirth);
      const YOB = DOB.getFullYear()
      const MOB = DOB.getMonth()
      const DayOB = DOB.getDate()
      if (currentMonth <= MOB && currentDay < DayOB) {
        return currentYear - YOB - 1;
      }
      return currentYear - YOB;
    },

    editScreen() {
      this.readOnly = false;
    },

    resetForm() {
      this.readOnly = true;
      this.selectedImage = null;
      this.fillSubject(this.$store.getters["cases/getSubject"]);
    },

    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          this.processForm();
        }
      });
    },

    processForm() {
      const endpoint = "subject/" + this.Subject.SubjectID;
      let clone = {};
      for (let key in this.Subject) {
        if (key === "SubjectAddress") {
          clone["Addresses"] = this.Subject[key];
        } else if (key !== "Cases" && key !== "Image") clone[key] = this.Subject[key];
      }
      this.setSubject(clone);
      let postData = this.$store.getters["cases/getSubject"];
      if (this.DateOfBirthComplete) {
        apiService.post(endpoint, postData).then(res => {
          if (res) {
            this.showToast('success', 'top-center', 4000, 'Subject saved!');
            if (res.data) {
              if (res.data.success) {
                this.fullDateOfBirth = "";
                this.fullDriversLicenseNo = "";
                this.fullSocialSecurityNo = "";
                this.readOnly = true;
                this.selectedImage = null;
                this.loadSubjectPage()
              } else {
                this.showToast('warning', 'top-center', 5000, res.data.message);
              }
            }
          }
        })
      } else {
        this.showToast('danger', 'top-center', 4000, 'DOB must be a valid date.');
        this.Subject.DateOfBirth = ""
        setTimeout(() => {
          this.Subject.DateOfBirth = null
          this.DateOfBirthComplete = true;
        })
      }
    },

    isValidDateOfBirth() {
      const currentDate = new Date()
      this.DateOfBirthComplete = this.Subject.DateOfBirth &&
          Number(this.Subject.DateOfBirth.substring(0, 4)) >= 1900 &&
              new Date(this.Subject.DateOfBirth) <= currentDate;
    },

    addAddress() {
      if (!this.Subject.SubjectAddress) this.Subject.SubjectAddress = [];
      const item = {}
      this.Subject.SubjectAddress.push(item)
    },

    updateAddress(val, index) {
      let item = {
        Address1: "",
        Address2: "",
        AddressID: "",
        AddressIndex: "",
        City: "",
        Country: "",
        Description: "",
        Name: "",
        PostalCode: "",
        StateProvince: "",
        SubjectID: "",
        ZipCode: "",
      }
      item = {...item, val}
      this.Subject.SubjectAddress.splice(index, 1, val);
    },

    deleteAddress(index) {
      this.Subject.SubjectAddress.splice(index, 1);
      this.setSubject(this.Subject);
    },

    uploadSubjectImage(item) {
      this.uploadImage(item.file)
    },

    uploadImage(image) {
      const _this = this;
      if (this.SubjectID) {
        const data = {
          ParentObjectKey: this.SubjectID,
          ParentObjectType: "Subject",
          UserTypePermissions: "User;Investigator;Client",
        }
        let formData = new FormData();
        const documentJson = JSON.stringify(data)
        formData.append("FormFile", image);
        formData.append("Document", documentJson);

        apiService
            .post("file/create", formData, {'Content-Type': 'multipart/form-data'})
            .then(function (res) {
              if (_this.selectedImage !== null && _this.RelatedImages[_this.selectedImage]) {
                const selectedImage = _this.RelatedImages[_this.selectedImage];
                _this.Subject.IsPhotoVerified = selectedImage.IsPhotoVerified;
                _this.Subject.PhotoSource = selectedImage.Source;
                _this.setSubject(_this.Subject);
                _this.processForm();
              } else {
                _this.loadSubjectPage()
              }
            })
            .catch(function (response) {
              console.log(response);
            });
      } else {
        this.showToast('danger', 'top-center', 4000, JSON.stringify("You don't have Subject id"));
      }
    },

    toggleVisibilityFields(fieldName, isEdit = false) {
      if (fieldName) this[fieldName] = !this[fieldName]
      if (fieldName && !!this[fieldName.substring(0, fieldName.length - 3)]) return

      if (this.fullDateOfBirth || this.fullDriversLicenseNo || this.fullSocialSecurityNo) {
        setTimeout(() => {
          if (isEdit) {
            if (this.fullDateOfBirth) this.Subject.DateOfBirth = this.tryGetDate(this.fullDateOfBirth)
            this.Subject.DriversLicenseNo = this.fullDriversLicenseNo
            this.Subject.SocialSecurityNo = this.fullSocialSecurityNo
          }
        }, 100)
        return;
      }
      apiService
          .get("subject/" + this.SubjectID + "/pii")
          .then(res => {
            this.fullDateOfBirth = res.data.dateOfBirth
            this.fullDriversLicenseNo = res.data.driversLicenseNo
            this.fullSocialSecurityNo = res.data.socialSecurityNo
            if (isEdit) {
              if (res.data.dateOfBirth) this.Subject.DateOfBirth = this.tryGetDate(res.data.dateOfBirth)
              this.Subject.DriversLicenseNo = res.data.driversLicenseNo
              this.Subject.SocialSecurityNo = res.data.socialSecurityNo
            }
          })
    },

    handleSocialSecurityNo() {
      if (this.Subject.SocialSecurityNo && this.Subject.SocialSecurityNo.length >= 11) {
        apiService
            .post("case/lookup/byssn", {ssn: this.Subject.SocialSecurityNo})
            .then((response) => {
              if ((response.data.length === 0) || (response.data.length === 1 && this.getCase.CaseID && response.data[0].CaseID === this.getCase.CaseID)) {
                console.log(response)
              } else {
                if (response.data.length > 1 && this.getCase.CaseID && response.data[0].CaseID === this.getCase.CaseID) {
                  this.matchCaseID = response.data[1].CaseID;
                } else {
                  this.matchCaseID = response.data[0].CaseID;
                }
                this.modalShow = true;
              }
            })
      }
    },

    reviewCase() {
      this.modalShow = !this.modalShow;
      let route = this.$router.resolve('/cases/' + this.matchCaseID + '/detail');
      window.open(route.href, '_blank');
    },

    chancelCase() {
      this.modalShow = !this.modalShow
    },

    ssnOnChange(val) {
      if (val && val.substring(0, 1) !== "X") {
        return val;
      }
    },

    showViewAllButton() {
      if (this.RelatedImages && this.RelatedImages.length) {
        const imagesLength = this.RelatedImages.filter(i => i.Image)
        return !!imagesLength.length
      }
      return false
    },

    viewAllImages() {
      this.$bvModal.show('modal-all-images');
    },

    changeImage() {
      const selectedImage = this.RelatedImages[this.selectedImage];
      this.setUploadUrl(selectedImage.Image);

      axiosIns.get(selectedImage.Image, { responseType: 'blob' })
          .then(response => {
            if (response && response.data) {
              const fileName = selectedImage.Image.split('/').pop() + this.getImageExtension(response.data.type)
              const imageToChange = new File([response.data], fileName, {type: response.data.type})
              this.uploadImage(imageToChange)
            }
          })
    },

    chooseImage(index) {
      if (this.UserType === 'User' && !this.readOnly) {
        this.selectedImage = index
      }
    },

    getImageExtension(mimeType) {
      const mimeTypes = {
        'image/jpeg': '.jpg',
        'image/png': '.png',
        'image/gif': '.gif',
        'image/webp': '.webp',
        'image/bmp': '.bmp',
        'image/svg+xml': '.svg',
        'image/tiff': '.tiff',
        'image/x-icon': '.ico',
        'image/vnd.microsoft.icon': '.ico'
      };
      return mimeTypes[mimeType] || "";
    }
  },
};
</script>

<style>
hr {
  border: 1px solid #ebe9f1;
}
.accordion-title h3 {
  color: white;
  font-weight: bold;
  margin: 0.5rem;
}
.accordion-title {
  background: #476dae;
  display: flex;
  align-items: center;
}
.accordion {
  margin-top: 0.2rem;
}
.collapse-arrow {
  width: 1rem;
  height: 1rem;
  margin: 0 1rem;
  border: 3px solid white;
  border-top: none;
  border-left: none;
  transform: rotate(-135deg);
  transition-duration: 200ms;
}
.collapse-arrow.open {
  transform: rotate(45deg);
}
.text-underline {
  text-decoration: underline !important;
}

/* I believe p-2 is a bootstrap class. This fixes the spacing on the cases/add page. */
.custom-p-2 {
  margin: 1.5rem;
  margin-left: 0;
}
.height-input input {
  padding-left: 0.2rem;
  padding-right: 0.2rem;
  width: 3.5rem;
}
.ice-icon {
  position: absolute;
  right: 1rem;
  top: 0.5rem;
  z-index: 3;
}
.unmasc-ssn {
  position: absolute;
  left: 3rem;
  background: #efefef;
  top: 0.7rem;
  font-size: 1rem;
}
#modal-all-images > div {
  max-width: 45% !important;
  width: fit-content;
}
.images-wrapper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  max-height: 19rem;
  overflow: auto;
}
.image-wrapper {
  width: 15rem;
  padding: 0.2rem;
  position: relative;
}
.link-title {
  position: absolute;
  bottom: 0.1rem;
  left: 0.5rem
}
.date-field {
  text-shadow: 1px 1px 1px white;
  font-size: 1rem;
  position: absolute;
  bottom: 1.3rem;
  left: 0.5rem
}
.link-text {
  text-shadow: 1px 1px 1px white;
}
.selected-image {
  border: 0.2rem solid #55fd55;
  padding: 0;
}
.img-block {
  display: block;
  max-width: 14.6rem;
  max-height: 9rem;
  margin: auto;
}
.verified {
  position: absolute;
  top: 0.2rem;
  right: 0rem;
}
.verified:hover .tooltip-source {
  display: block;
}
.tooltip-source {
  display: none;
  position: absolute;
  top: 2rem;
  color: #156f31;
  width: max-content;
  font-size: 1rem;
  right: 1rem;
  text-shadow: 1px 1px 1px white;
}
</style>
